import axios from 'axios'
import config from '@/config'
import { GetQueryString } from '@/utils/common'
import { showToast } from 'vant'

const request = axios.create({
    baseURL: process.env.VUE_APP_FLAG === 'prod' ? config.baseUrl.prod : config.baseUrl.dev,
    timeout: 10000,
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})

// 请求数据拦截
request.interceptors.request.use(
    config => {
        if (config.method === 'get') {
            const qd = GetQueryString('qd')
            config.params = {
                ...config.params,
                qd
            }
        }
        return config
    },
    error => {
        return Promise.reject(error)
    }
)

// 响应数据拦截
request.interceptors.response.use(
    res => {
        if (res.data.resultCode !== '100000') {
            showToast(res.data.errorMsg)
            return Promise.reject(res.data)
        }
        return res.data
    },
    error => {
        return Promise.reject(error)
    }
)
export default request
